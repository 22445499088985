.home {
    .section__part:last-child {
        margin-top: 10rem;
    }

    .speech__logo {
        margin: 2rem 0;
        height: 1rem;

        img {
            object-fit: cover;
            object-position: center;
        }
    }

    .section__button {
        text-align: center;

        @include media-breakpoint-up(lg) {
            text-align: left;
        }
    }

    .section__background {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;

        .image__container {
            width: 100%;
            overflow: hidden;
            position: relative;
            height: 200vh;

            @include media-breakpoint-down(sm) {
                .image__main {
                    position: absolute;
                    right: -47%;
                    width: 18rem;
                    top: 34rem;
                }

                .image__main-assets {
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 0;
                    z-index: 0;
                    width: 24rem;
                }

                .image__onion {
                    position: absolute;
                    right: 0;
                    top: 35rem;
                    width: 7rem;
                    z-index: 0;
                }

                .image__garlic {
                    position: absolute;
                    left: 26%;
                    top: 42rem;
                    width: 7rem;
                    transform: initial !important;
                }

                .image__main-leg {
                    position: absolute;
                    width: 24rem;
                    top: 5rem;
                    transform: rotate(96deg) !important;
                    left: 48%;
                }

                .image__broccoli {
                    position: absolute;
                    width: 10rem;
                    top: 34rem;
                    left: -13%;
                    transform: initial !important;
                }

                .image__mushroom {
                    position: absolute;
                    width: 5rem;
                    top: 41rem;
                    left: 59%;
                    z-index: -5;
                    transform: initial !important;
                }

                .image__thyme {
                    position: absolute;
                    width: 9rem;
                    top: -65px;
                    left: -12%;
                    z-index: -1;
                    transform: rotate(153deg) !important;
                }
            }


            @include media-breakpoint-up(md) {
                .image__main {
                    position: absolute;
                    right: -18%;
                    z-index: 0;
                    width: 20rem;
                    top: 0;
                }

                .image__main-assets {
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 0;
                    z-index: 0;
                    width: 24rem;
                }

                .image__onion {
                    position: absolute;
                    right: 0;
                    top: 35rem;
                    width: 7rem;
                    z-index: 0;
                }

                .image__garlic {
                    position: absolute;
                    right: 0;
                    top: 34rem;
                    width: 9rem;
                    z-index: -1;
                }

                .image__main-leg {
                    position: absolute;
                    width: 44rem;
                    top: 28rem;
                    z-index: -1;
                }

                .image__broccoli {
                    position: absolute;
                    width: 14rem;
                    top: 32rem;
                    left: 30%;
                    z-index: 0;
                }

                .image__mushroom {
                    position: absolute;
                    width: 8rem;
                    top: 20rem;
                    left: 57%;
                    z-index: -5;
                }

                .image__thyme {
                    position: absolute;
                    width: 15rem;
                    top: 30rem;
                    left: 10%;
                    z-index: -5;
                }
            }


            @include media-breakpoint-up(lg) {
                .image__main {
                    position: absolute;
                    right: 0;
                    z-index: 0;
                    width: 32rem;
                    top: 8rem;
                }
            }
        }
    }
}
