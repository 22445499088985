.application {
    height: 470px;
    background-color: $lightGreen;

    .image_column {
        position: relative;
        min-width: 18rem;
        height: 100%;
        flex-basis: 20%;

        .image__container {
            position: absolute;
            top: -40px;

            img {
                object-fit: contain;
                object-position: center;
                width: 100%;
            }
        }
    }

    .section__title {
        width: 20rem;
    }

    .section__body {
        width: 30rem;
    }
}
