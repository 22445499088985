.recette {
    background-color: $lightGrey;
    margin-top: 5rem;

    .section__background {
        position: absolute;
        right: 0;
        margin-top: -5rem;
    }

    .section__title, .section__body {
        @include media-breakpoint-down(sm) {
            transform: initial !important;
        }
    }

    .image__container {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        width: 25rem;

        img {
            position: relative;
            width: 57%;
            top: -10rem;
            object-fit: cover;
            object-position: center;

            @include media-breakpoint-down(md) {
                transform: initial !important;
            }


            @include media-breakpoint-up(md) {
                width: 80%;
                top: -14rem;
            }


            @include media-breakpoint-up(lg) {
                width: 100%;
                top: 0rem;
            }
        }
    }

    .section__button {
        text-align: center;

        @include media-breakpoint-up(lg) {
            text-align: left;
        }
    }
}
