[data-aos="custom-fade-left"] {
    opacity: 0;
    transition-property: transform, opacity;

    &.aos-animate {
        opacity: 1;
    }

    @media screen and (min-width: 768px) {
        transform: translateX(100px);

        &.aos-animate {
            transform: translateX(0);
        }
    }
}

[data-aos="custom-fade-down-left"] {
    opacity: 0;
    transition-property: transform, opacity;

    &.aos-animate {
        opacity: 1;
    }

    @media screen and (min-width: 768px) {
        transform: translate(100px, -100px);

        &.aos-animate {
            transform: translate(0);
        }
    }
}

[data-aos="custom-fade-down"] {
    opacity: 0;
    transition-property: transform, opacity;

    &.aos-animate {
        opacity: 1;
    }

    @media screen and (min-width: 768px) {
        transform: translateY(-100px);

        &.aos-animate {
            transform: translateY(0);
        }
    }
}

[data-aos="custom-fade-up-left"] {
    opacity: 0;
    transition-property: transform, opacity;

    &.aos-animate {
        opacity: 1;
    }

    @media screen and (min-width: 768px) {
        transform: translate(100px, 100px);

        &.aos-animate {
            transform: translate(0);
        }
    }
}

[data-aos="custom-fade-up"] {
    opacity: 0;
    transition-property: transform, opacity;

    &.aos-animate {
        opacity: 1;
    }

    @media screen and (min-width: 768px) {
        transform: translateY(100px);

        &.aos-animate {
            transform: translateY(0);
        }
    }
}
