.signup {
    background-color: $lightGrey;

    .section__form {
        .js--email-control {
            &.valid {
                box-shadow: 0px 0px 5px 2px rgba(31, 158, 82, 0.8);
            }

            &.invalid {
                box-shadow: 0px 0px 5px 2px rgba(215, 40, 40, 0.8);
            }

            &.success {
                width: 0;
            }

            input {
                &:focus {
                    box-shadow: none;
                }
            }
        }
    }

    .section__alert {
        .form--alert {
            color: rgb(215, 40, 40);
        }

        .icon--alert {
            height: 22px;
            width: 22px;
        }

        .js--alert-control {
            display: none;

            &.invalid {
                display: flex;
            }
        }
    }

    .btn--custom {
        background-color: $lightGreen;
        border-color: $lightGreen;
        color: white;
        padding: 1.3rem 1.2rem;
        border-radius: 5px;
        font-size: 1em;
        width: 100%;
        transition: background-color color  0.6s;
        transition: width  1.5s;

        &.success {
            width: 40%;
            background-color: $green;
        }

        &.error {
            background-color: red;
            border-color: red;
        }
    }
}
