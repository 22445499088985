.conviction {
    overflow: hidden;

    .image__container {
        width: 5rem;

        @include media-breakpoint-up(lg) {
            width: 7rem;
        }


        img {
            object-fit: contain;
            object-position: center;
            width: 100%;
        }
    }
}
