body {
    font-family: GothamRnd-Light;
    letter-spacing: 0.05rem;
}

.horizontal-center {
    display: flex;
    justify-content: center;
}

.section__container {
    padding: 5.5rem 0;

    @include media-breakpoint-up(sm) {
        padding: 8rem 0;
    }
}

.section_body {
    font-size: 1.2rem;
}

p {
    color: $black;
    font-size: 1.5rem;
    line-height: 1.9rem;

    &.p--small {
        font-size: 1rem;
        line-height: 1.2rem;
    }
}

h1, h2 {
    font-family: GothamRnd-Bold;
}

h1 {
    font-size: 2.5rem;

    @include media-breakpoint-up(sm) {
        font-size: 3.5rem;
    }
}

h2 {
    font-size: 1.7rem;
    line-height: 2.2rem;
    margin: 1rem 0;
}

strong {
    font-family: GothamRnd-Bold;
    color: $lightGreen;
}

.input-group {
    border-radius: 4px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);

    .form-control {
        height: 4.7rem;
        padding-left: 2rem;
        border-color: transparent;

        &::placeholder {
            color: #979797;
        }
    }
}

.btn {
    margin: 3rem 0;
    font-family: GothamRnd-Bold;
    padding: 1.3rem 1.2rem;
    border-radius: 5px;
    font-size: 1em;

    @include media-breakpoint-up(sm) {
        margin: 0.5rem 0;
    }


    &.btn-custom--white {
        background-color: white;
        border-color: white;
        color: $lightGreen;

        &:hover {
            background-color: white;
            border-color: white;
            color: $darkGreen;
        }
    }

    &.btn-custom--green {
        background-color: $lightGreen;
        border-color: $lightGreen;
        color: white;
    }

    &.btn-custom--labeled {
        padding: 0 0.5rem;
        margin: 0;
    }
}

.color--black {
    color: $darkGreen;
}

.color--white {
    color: white;
}
