.header__main {
    .header__container {
        position: absolute;
        top: 1rem;
        left: 2rem;

        &.mobile {
            left: 0;
        }
    }
}
